<template>
  <div class="routeEnWrap en">
    <m-layout-bar>
      <div class="ticketEnWrap">
        <div class="banner">
          <img class="banner" :src="require('../assets/image/route_en/banner.png')" alt />
        </div>
        <div class="content">
          <div class="fixedMenus" :class="barFixed?'show':''">
            <a
              class="fixedMenu qinZiLeYuan trans"
              :class="{'active':activeName==='qinZiLeYuan'}"
              data-name="qinZiLeYuan"
              @click="clickMenuItem('qinZi',$event)"
            >
              <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
              Parent child
              paradise
            </a>
            <a
              class="fixedMenu tianYuan trans"
              :class="{'active':activeName==='tianYuan'}"
              data-name="tianYuan"
              @click="clickMenuItem('tianYuan',$event)"
            >
              <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
              Leisure
              countryside
            </a>
            <a
              class="fixedMenu kangYang trans"
              :class="{'active':activeName==='kangYang'}"
              data-name="kangYang"
              @click="clickMenuItem('kangYang',$event)"
            >
              <!-- <img :src="require('../../assets/image/stay/star.png')" alt /> -->
              Health resort
            </a>
          </div>

          <div id="i_menusWrap" class="menusWrap">
            <!-- <div class="menuWrap"> -->
            <a
              class="menuWrap qinZiLeYuan maskWrap size toShow"
              :class="{'active':activeName==='qinZiLeYuan'}"
              data-name="qinZiLeYuan"
              @click="clickMenuItem('qinZi',$event)"
            >
              <img :src="require('../assets/image/route/tab_1.png')" alt />
              <span>
                Parent child
                paradise
              </span>
              <!-- <img :src="require('../assets/image/route_en/tab3.png')" alt /> -->
              <!-- <span>亲子乐园</span> -->
            </a>
            <!-- </div> -->
            <!-- <div class="menuWrap"> -->
            <a
              class="menuWrap tianYuan maskWrap size toShow"
              :class="{'active':activeName==='tianYuan'}"
              data-name="tianYuan"
              @click="clickMenuItem('tianYuan',$event)"
            >
              <img :src="require('../assets/image/route/tab_2.png')" alt />
              <span>
                Leisure
                countryside
              </span>
              <!-- <img :src="require('../assets/image/route_en/tab2.png')" alt /> -->
              <!-- <span>田园休闲</span> -->
            </a>
            <!-- </div> -->
            <!-- <div class="menuWrap"> -->
            <a
              class="menuWrap kangYang maskWrap size toShow"
              :class="{'active':activeName==='kangYang'}"
              data-name="kangYang"
              @click="clickMenuItem('kangYang',$event)"
            >
              <img :src="require('../assets/image/route/tab_3.png')" alt />
              <span>Health resort</span>
              <!-- <img :src="require('../assets/image/route_en/tab3.png')" alt /> -->
            </a>
            <!-- </div> -->
          </div>

          <!-- 1.亲子乐园 -->
          <div id="qinZi" class="group">
            <img class="banner" :src="require('../assets/image/route_en/g1.png')" alt />
            <div class="s1 starsGroup">
              <m-star-bar
                class="starBar"
                title="Joy coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="67"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Leisure coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="34"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Squeal coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="40"
              ></m-star-bar>
            </div>

            <div class="s2 starsGroup">
              <m-star-bar
                class="starBar"
                title="Joy coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="67"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Leisure coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="34"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Squeal coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="40"
              ></m-star-bar>
            </div>
          </div>
          <div id="tianYuan" class="group">
            <img class="banner" :src="require('../assets/image/route_en/g2.png')" alt />
            <div class="s3 starsGroup">
              <m-star-bar
                class="starBar"
                title="Joy coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="67"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Leisure coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="34"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Squeal coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="40"
              ></m-star-bar>
            </div>
          </div>
          <div id="kangYang" class="group">
            <img class="banner" :src="require('../assets/image/route_en/g3.png')" alt />
            <div class="s4 starsGroup">
              <m-star-bar
                class="starBar"
                title="Joy coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="67"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Leisure coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="34"
              ></m-star-bar>
              <m-star-bar
                class="starBar"
                title="Squeal coefficient:"
                :titleFontSize="18"
                fontWeight="bold"
                verticalAlign="top"
                titleColor="#333"
                :marginRight="40"
              ></m-star-bar>
            </div>
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar_en";
import TitleGapBar from "@/components/TitleGapBar";
import StarBar from "@/components/starBar";
import mx_utils from "@/mixins/mx_utils";
export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    [TitleGapBar.name]: TitleGapBar,
    [StarBar.name]: StarBar
  },
  data() {
    return {
      barFixed: false,
      activeName: ""
    };
  },
  mixins: [mx_utils],
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (!document.querySelector("#i_menusWrap")) return;
      let distOffsetTop = document.querySelector("#i_menusWrap").offsetTop;

      if (parseInt(distOffsetTop).toString() === "NaN") {
        return;
      }
      if (scrollTop > distOffsetTop - 95) {
        this.barFixed = true;
      } else {
        this.barFixed = false;
      }

      //滚动到亲子乐园的范围1126
      if (scrollTop > 500) {
        this.activeName = "qinZiLeYuan";
      }
      // 滚动到田园休闲的范围2638 tianYuan
      if (scrollTop > 1538) {
        this.activeName = "tianYuan";
      }

      //滚动到康养度假的范围3541 kangYang
      if (scrollTop > 2116) {
        this.activeName = "kangYang";
      }
      console.log("window scrollTop:", scrollTop);
      // console.log("目标 distOffsetTop:", distOffsetTop);
      // console.log("fixed标识：", this.barFixed);
    },
    clickMenuItem(anchorId, e) {
      if (!e.currentTarget.getAttributeNode("data-name")) return;
      this.activeName = e.currentTarget.getAttributeNode("data-name").value;
      if (!this.activeName) {
        this.activeName = "";
      }
      this.goNavArea(anchorId);
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";
.ticketEnWrap {
  > .content {
    width: 1400px;
    margin: 53px auto 90px;

    .fixedMenus {
      position: fixed;
      top: 120px;
      width: 1280px;
      z-index: 999;
      display: none;
      left: 0;
      right: 0;
      margin: 0 auto;

      &.show {
        display: block;
      }
      > .fixedMenu {
        display: inline-block;
        width: 426px;
        height: 50px;
        line-height: 50px;
        background: $font-grayDarkD;
        font-size: 28px;
        font-weight: bold;
        color: $main-white;
        text-align: center;
        border-right: 1px solid $font-grayDark;

        > img {
          margin-right: 10px;
        }
        &.qinZiLeYuan {
          border-bottom: 10px solid $main-blue;
          // border-bottom-left-radius: 10px;
          &:hover,
          &.active {
            background: $main-blue;
          }
        }
        &.tianYuan {
          border-bottom: 10px solid $main-greenGrass;
          &:hover,
          &.active {
            background: $main-greenGrass;
          }
        }
        &.kangYang {
          border-bottom: 10px solid $main-purple;
          // border-bottom-right-radius: 10px;
          &:hover,
          &.active {
            background: $main-purple;
          }
        }
      }
      > .fixedMenu:nth-of-type(3) {
        border-right: 0;
        // margin-left:5px;
      }
    }

    > .menusWrap {
      width: 1090px;
      margin: 23px auto 0 auto;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      .menuWrap {
        position: relative;
        display: inline-block;
        width: 253px;
        height: 68px;
        line-height: 68px;
        background: $main-blue;
        text-align: center;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        &.tianYuan {
          background: $main-greenGrass;
        }
        &.kangYang {
          > img {
            top: -21px;
            left: -41px;
          }
          background: $main-purple;
          > span {
            width: 100%;
            line-height: 68px;
            padding-left: 14px;
            margin-top: 0;
          }
        }

        img {
          position: absolute;
          top: -20px;
          left: -26px;
          cursor: pointer;
        }
        span {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: $main-white;
          word-wrap: break-word;
          width: 116px;
          line-height: 1.15;
          padding-left: 78px;
          margin-top: 10px;
        }
      }
    }
    .menuWrap.qinZiLeYuan {
      margin-left: 10px;
    }
    #qinZi {
      margin-top: 58px;
    }
    #tianYuan,
    #kangYang {
      margin-top: 41px;
    }

    .group {
      position: relative;
      > .starsGroup {
        position: absolute;
        > .starBar {
          margin-top: 30px;
        }
      }
      > .s1.starsGroup {
        position: absolute;
        left: 30px;
        top: 327px;
      }
      > .s2.starsGroup {
        position: absolute;
        left: 33px;
        bottom: 65px;
      }
      > .s3.starsGroup {
        position: absolute;
        left: 33px;
        bottom: 65px;
      }
      > .s4.starsGroup {
        position: absolute;
        left: 33px;
        bottom: 60px;
      }
    }
  }
}
</style>

